.block-productlast {
    padding: 3.75rem 0;
    background-color: var(--productlast-block-bg);
    color: var(--productlast-block-text-color);
}
.productlast-filters {
    display: block;
    margin: 0 auto 1.5rem;
    text-align: center;
}
