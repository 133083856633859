.block-news {
    padding: 3.75rem 0;
    .card {
        box-shadow: $box-shadow-sm;
        background-color: transparent;
        border: 1px solid rgba(164,174,198,.2);
        figure {
            position: relative;
        }
        .overlay {
            box-sizing: border-box;
            * {
                color: #fff;
            }
            figcaption {
                position: absolute;
                width: 100%;
                height: 100%;
                padding: 0.75rem 1rem;
                top: 0;
                right: 0;
                left: 0;
                bottom: 0;
                opacity: 0;
                text-align: center;
            }
            .from-top {
                position: absolute;
                padding: 0.75rem 1rem;
                top: 50%;
                left: 0;
                width: 100%;
                margin: 0;
                transform: translateY(-80%);
            }
        }
        .overlay a {
            position: relative;
            display: block;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 3;
            cursor: pointer;
        }
        .overlay span.bg {
            display: block;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            cursor: pointer;
            opacity: 0;
            z-index: 4;
            position: absolute;
            width: 100%;
            height: 100%;
            background: rgba(30,34,40,.6);
        }
        .hover-scale {
            overflow: hidden;
            -webkit-backface-visibility: hidden;
            -moz-backface-visibility: hidden;
            transform: translateY(0);
            @include transition(all 0.2s ease);
        }
        .hover-scale:hover img {
            transform: scale(1.05);
        }
        .post-title {
            font-weight: 700;
            margin-bottom: 1rem;
        }
        .category {
            font-weight: 700;
            text-transform: uppercase;
        }
        a {
            text-decoration: none;
        }
    }
    .card-body {
        padding: 1.5rem;
        .post-meta {
            list-style: none;
            padding: 0;
            margin: 0 0 0.5rem;
            font-size: .875rem;
            color: rgba($body-color, .75);
            display: flex;
            i {
                margin-right: .75rem;
            }
        }
    }
    .card-footer {
        background-color: transparent;
        border-top: 0;
        padding: 0 1.5rem 1rem;
        .post-meta {
            list-style: none;
            padding: 0;
            margin: 0;
            font-size: 1rem;
            color: rgba($body-color, .75);
            display: flex;
        }
    }
    .pagination-wrapper {
        nav {
            justify-content: center;
            text-align: center;
            display: flex;
            margin-top: 1rem;
        }
    }
}

.block-news.style-2 {
    .card-news.style-2 {
        figure {
            margin: 0;
        }
        .post-category {
            position: absolute;
            background: $primary;
            top: 0;
            left: 0;
            padding: 0.25rem 1rem;
            z-index: 2;
            .category {
                color: #fff;
            }
        }
        .card-body {
            position: absolute;
            bottom: 20px;
            text-align: left;
            max-width: 70%;
            background: rgba(0,0,0,0.5);
            padding: 1rem 1.5rem;
            display: inline-block;
            left: 20px;
            z-index: 2;
            .post-title {
                margin-bottom: 0;
                font-size: 1.5rem;
            }
            .post-meta {
                color: #fff;
                margin: 0;
            }
            a {
                color: #fff;
            }
        }
    }
}

.block-news.style-3 {
    .card-news {
        box-shadow: none;
        background-color: transparent;
        border: 0;
        figure {
            margin: 0;
        }
        .post-date {
            position: absolute;
            background: rgba(0,0,0,0.7);
            z-index: 2;
            padding: 10px 15px;
            color: #fff;
            display: flex;
            flex-direction: column;
            align-items: center;
            line-height: 1.3;
            text-transform: uppercase;
        }
    }
}
