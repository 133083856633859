.block-slideshow {
    padding: 0;
    background-color: var(--slideshow-block-bg);
    color: var(--slideshow-block-text-color);
    position: relative;
    overflow: hidden;
    border: var(--slideshow-block-thickness-border, 0) solid var(--slideshow-block-color-border, transparent);

    &.searchbar-exist {
        .owl-block-slideshow.owl-theme {
            .owl-dots {
                bottom: 70px;
            }
            &[data-animatein="animate__slideInUp"] {
                .owl-dots {
                    bottom: 50%;
                }
            }
        }
    }
    .searchbar {
        position: relative;
        z-index: 8;
        width: 100%;
        bottom: 0;
        transform: translateY(-50%);
        .card {
            border: 0;
            padding: 1.5rem;
            box-shadow: $box-shadow;
            background-color: #fff;
        }
    }
    .searchbar + .owl-block-carousel.owl-theme {}
}

.owl-block-slideshow.owl-theme {
    position: relative;
    z-index: 7;
    .item picture {
        position: relative;
        z-index: 1;
        width: 100%;
        img {
            width: 100%;
            height: var(--slideshow-block-height, 680px);
            object-fit: cover;
            object-position: top;
        }
    }
    .item:before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        width: 100%;
        height: 100%;
    }
    .item .title {
        font-size: 2.8rem;
        font-weight: 700;
        margin-bottom: 1rem;
        color: var(--slideshow-block-title-color);
    }
    .item .caption {
        position: absolute;
        bottom: 0;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        left: 0;
        right: 0;
        z-index: 10;

        &.alignment-1, &.alignment-2, &.alignment-3 {
            justify-content: flex-start;
        }
        &.alignment-4, &.alignment-5, &.alignment-6 {
            justify-content: center;
        }
        &.alignment-7, &.alignment-8, &.alignment-9 {
            justify-content: flex-end;
        }

        &.alignment-1, &.alignment-4, &.alignment-7 {
            text-align: left;
            align-items: flex-start;
        }
        &.alignment-2, &.alignment-5, &.alignment-8 {
            text-align: center;
            align-items: center;
        }
        &.alignment-3, &.alignment-6, &.alignment-9 {
            text-align: right;
            align-items: flex-end;
        }

        .description {
            margin-top: 0;
            margin-bottom: 0;
            line-height: 1.4;
            font-size: 24px;
            color: var(--slideshow-block-text-color);
        }
        .caption-inner {
            display: inline-block;
            padding: var(--slideshow-block-caption-padding, 30px);
            background-color: var(--slideshow-block-caption-bgcolor, transparent);
        }
    }
    .owl-dots {
        position: absolute;
        bottom: 15px;
        width: 100%;
        .owl-dot span {
            width: 20px;
            background-color: rgba($white, 1);
            height: 5px;
            margin: 5px;
        }
        .owl-dot.active span,
        .owl-dot:hover span {
            background-color: var(--button-color);
        }
    }
    .owl-item.active {
        h1.title {
            -webkit-animation-duration: 1s;
            animation-duration: 1s;
            -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
            animation-name: fadeInDown;
            animation-delay: 0.3s;
        }
        .description {
            -webkit-animation-duration: 1s;
            animation-duration: 1s;
            -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
            animation-name: fadeInUp;
            animation-delay: 0.6s;
        }
        a.btn {
            -webkit-animation-duration: 1s;
            animation-duration: 1s;
            -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
            animation-name: fadeInUp;
            animation-delay: 0.9s;
        }
    }

    &[data-animatein="animate__slideInUp"] {
        .owl-dots {
            bottom: 50%;
            width: auto;
            right: 15px;
            display: flex;
            flex-direction: column;
            margin: 0;
            transform: translateY(50%);
        }
        .owl-dots .owl-dot span {
            width: 5px;
            height: 20px;
        }
        .owl-dots .owl-dot.active span {
            height: 40px;
        }
    }
}

@include media-breakpoint-down(lg) {
    .block-slideshow {
        .searchbar {
            transform: translateY(-15%);
        }
    }
}

@include media-breakpoint-down(md) {
    .owl-slideshow .animated {
        margin-top: 0;
    }
}

@include media-breakpoint-down(sm) {
    .owl-slideshow .item picture img {
        aspect-ratio: 1 / 1.2;
    }
}
