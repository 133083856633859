.glightbox-container.glightbox-clean {
    .gslide-description {
        background: transparent;
    }
    .gdesc-inner {
        padding: 15px;
    }
    .gslide-title {
        font-size: 16px;
        font-family: inherit;
        color: #fff;
        margin-bottom: 0;
        line-height: 1;
    }
}
