.block-banner {
    .card {
        border-radius: 0;
        border: 0;
        overflow: hidden;
        background-color: transparent;
        &:hover .card-img {
            transform: scale(1.20);
        }
    }
    .card-img {
        aspect-ratio: 48 / 36;
        object-fit: cover;
        border-radius: 0;
        transition: all .35s;
    }
    .card-img-overlay {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        background: linear-gradient(to bottom,rgba(0,0,0,0) 0,rgba(0,0,0,0.9) 100%);
        transition: all .35s;
        border-radius: 0;
        padding: 1.5rem;
        align-items: flex-start;
    }
    .title {
        font-weight: 700;
        line-height: 1.2;
        color: var(--banner-block-title-color);
    }
    .description {
        line-height: 1.2;
        margin-bottom: 1rem;
        color: var(--banner-block-description-color);
    }
}
