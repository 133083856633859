ul.style-1 {
    padding-left: 1.2rem;
    li {
        padding-inline-start: 10px;
    }
    li::marker {
        content: "\f00c";
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        color: var(--bs-btn-bg, $primary);
    }
}
ul.style-danger {
    padding-left: 1.2rem;
    li {
        padding-inline-start: 10px;
    }
    li::marker {
        content: "\f00c";
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        color: $danger;
    }
}
