.navbar {
    .dropdown-item.dropdown-toggle {
        justify-content: space-between;
        align-items: center;
        display: flex;
    }
    .dropdown-toggle::after {
        font-family: 'bootstrap-icons';
        content: '\F282';
        font-weight: normal;
        border: 0;
        vertical-align: middle;
    }
    .has-submenu .dropdown-toggle::after {
        content: '\F284';
        margin-left: 0.95em;
    }
    .has-submenu > .dropdown-item > span.drop-toggle {
        padding: 0 8px;
    }
}
.dropdown-item {
    font-size: var(--body-font-size);
}

.dropdown-item:active, .dropdown-item.active,
.dropdown-item:hover, .dropdown-item:focus {
    color: var(--bs-btn-color);
    text-decoration: none;
    background-color: var(--bs-btn-bg);
}

.drop-categories {
    font-size: var(--drop-categories-font-size);
    .navbar-nav, .nav-item {
        width: 100%;
    }
    .nav-link {
        text-align: center;
        border-radius: .25rem;
        background-color: var(--drop-categories-bg);
        border: 1px solid var(--drop-categories-border);
        color: var(--drop-categories-dropdown-color);
        font-size: var(--drop-categories-font-size);
    }
    .dropdown-menu {
        background-color: var(--drop-categories-dropdown-bg);
        width: 100%;
        border: 0;
    }
    .dropdown-item {
        display: flex;
        justify-content: space-between;
        font-size: var(--drop-categories-dropdown-font-size);

        --bs-dropdown-item-padding-y: 5px;
        --bs-dropdown-link-hover-bg: rgba(#000, .75);
    }
    .navbar .nav-item .submenu {
        top: 0;
    }
}

@include media-breakpoint-up(lg) {
    .navbar {
        .dropdown-menu {
            top: 180%;
            li {
                position: relative;
                &:hover > .submenu {
                    display: block;
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
        .dropdown-item {
            white-space: normal;
            width: 240px;
        }
        .nav-item-lang.dropdown {
            -bs-dropdown-item-padding-x: 1em;
            .dropdown-item {
                width: auto;
            }
        }
        .nav-item {
            .dropdown-menu {
                display:block;
                opacity: 0;
                visibility: hidden;
                transition: .4s;
                margin-top: 0;
            }
            &:hover {
                & > .dropdown-menu {
                    opacity: 1;
                    visibility:visible;
                    top: 100%;
                    transform: rotateX(0deg);
                    &.megamenu {}
                }
            }
        }
        .has-submenu > .dropdown-toggle:after {
            transform: rotate(180deg);
        }
        .nav-item .submenu {
            display: none;
            position: absolute;
            left: 100%;
            top: 30%;
            transition: .8s;
            &.left {
                transform: translate(-100%, -25px);
                left: 0;
                right: auto;
                transition: 0s;
            }
            &.right {
                transform: translate(100%, -25px);
                left: auto;
                right: 1px;
                transition: 0s;
            }
        }
        .nav-item .submenu-left {
            right: 100%;
            left: auto;
        }
    }
}

@include media-breakpoint-down(lg) {
    .navbar {
        .dropdown-menu .dropdown-menu {
            padding: 0;
            margin: 0;
        }
        .dropdown-toggle {
            display: flex;
            justify-content: space-between;
        }
        .nav-item .submenu.show {
            display: block;
            position: relative;
            border-top: 1px solid white;
            border-bottom: 1px solid white;
            border-radius: 0;
        }
        .megamenu {
            .menu-title.dropdown-item {
                margin: 0;
                &:hover, &:focus {
                    background-color: transparent;
                }
                a {
                    text-decoration: none;
                }
            }
        }
    }
    .drop-categories .dropdown-item {
        --bs-dropdown-item-padding-x: 1rem;
    }
}
