@include media-breakpoint-down(md) {
    .table-fluid {
        border: 0;
        & > thead {
            border: none;
            clip: rect(0 0 0 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
        }
        & > thead >tr, & > tbody >tr, & > tfoot >tr {
            border: 1px solid rgba(0,0,0,.125);
            display: flex;
            flex-wrap: wrap;
            padding: 0.5rem;
            height: auto;
            & + tr {
                margin-top: 0.5rem;
            }
            &:empty {
                padding: 0;
                border: 0;
                margin: 0;
            }
            & > th, & > td {
                display: flex;
                align-items: center;
                padding: .3rem;
                text-align: right;
                width: 100%;
                &.fluid-wrap {
                    flex-wrap: wrap;
                    &[data-column]::before {
                        width: 100%;
                    }
                }
                &[data-column]::before {
                    padding-right: 1rem;
                    font-weight: 600;
                    display: block;
                    margin-right: auto;
                    text-align: left;
                    content: attr(data-column);
                }
            }
            & > th:first-child, & > td:first-child {
                border-top: 0;
            }
            & > th.d-none, & > td.d-none {
                & + th, & + td {
                    border-top: 0;
                }
            }
        }
        & > tfoot >tr {
            border-top: 0;
            padding: .25rem .5rem;
        }
    }
}
