.block-brand {
    padding: 3.75rem 0;
    background-color: var(--brand-block-bg);
    color: var(--brand-block-text-color);

    .title {
        font-weight: 700;
        margin-bottom: 2rem;
        color: var(--brand-block-title-color);
    }

    .owl-carousel .owl-item .item img {
        max-width: 100%;
        width: auto;
    }

    .owl-theme.owl-block-carousel {
        .owl-dots {
            position: absolute;
            bottom: 15px;
            width: 100%;
            .owl-dot span {
                width: 20px;
                background-color: rgba($white, 1);
                height: 5px;
                margin: 5px;
            }
            .owl-dot.active span,
            .owl-dot:hover span {
                background-color: var(--button-color);
            }
        }
    }
}
