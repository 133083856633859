.block-faq {
    padding: 3.75rem 0;
    background-color: var(--faq-block-bg);
    color: var(--faq-block-text-color);

    .title {
        font-weight: 700;
        margin-bottom: 1.5rem;
        color: var(--faq-block-title-color);
    }

    .accordion-item {
        margin: 1rem 0;
        border-radius: var(--bs-accordion-border-radius);
        box-shadow: $box-shadow-lg;
        .accordion-button {
            border-radius: var(--bs-accordion-inner-border-radius);
            font-weight: 700;
            &:not(.collapsed) {
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
            }
        }
    }
    .accordion-item:not(:first-of-type) {
        border-top: 1px solid $accordion-border-color;
    }
    ul li {
        padding-inline-start: 1ch;
    }
    ul li::marker {
        content: escape-svg($check-icon);
    }
}
