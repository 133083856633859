.has-float-label {
    display: block;
    position: relative;
}
.has-float-label > label {
    cursor: text;
    opacity: 1;
    -webkit-transition: all .2s;
    transition: all .2s;
    top: -7px;
    left: 0.75rem;
    z-index: 3;
    line-height: 1;
    padding: 0 1px;
    font-weight: 600;
    margin: 0;
    font-size: 13px;
}
.has-float-label > label.floated {
    position: absolute;
    top: -0.55em;
    left: 0.5rem;
    max-width: calc(100% - 0.65rem);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &:after {
        content: " ";
        display: block;
        position: absolute;
        background: white;
        height: 2px;
        top: 50%;
        left: -0.2em;
        right: -0.2em;
        z-index: -1;
    }
}
