.block-hero {
    padding: 3.75rem 0;
    position: relative;
    background-position: bottom center;
    background-color: var(--hero-block-bg);
    color: var(--hero-block-text-color);

    &:before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        width: 100%;
        height: 100%;
        background: var(--hero-overlay-bg);
    }

    .container {
        position: relative;
        z-index: 3;
    }

    .title {
        font-weight: 700;
        margin-bottom: 0.25rem;
        color: var(--hero-block-title-color);
    }
    .description {
        color: var(--hero-block-text-color);
    }
    .hero-img-block {
        text-align: center;
        img {
            height: 100%;
            width: auto;
        }
    }
}
