.image-wrapper {
    background: no-repeat center center;
    background-size: cover;
    position: relative;
    z-index: 0;
    &:not(.mobile) {
        background-attachment: fixed;
    }
    &.bg-full {
        background-size: 100%;
        background-position: center center;
        background-repeat: no-repeat;
        background-attachment: scroll;
    }
    &.bg-cover {
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        background-attachment: scroll;
    }
    &.bg-overlay img.img-cover {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        object-fit: cover;
    }
}

.bg-overlay {
    &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        width: 100%;
        height: 100%;
        background: rgba($black, 0.5);
    }
    &.bg-overlay-black-00:before {
        background: rgba($black, 0.0);
    }
    &.bg-overlay-black-10:before {
        background: rgba($black, 0.1);
    }
    &.bg-overlay-black-20:before {
        background: rgba($black, 0.2);
    }
    &.bg-overlay-black-50:before {
        background: rgba($black, 0.5);
    }
    &.bg-overlay-black-80:before {
        background: rgba($black, 0.8);
    }
    &.bg-overlay-black-90:before {
        background: rgba($black, 0.9);
    }
    &:not(.bg-content) * {
        position: relative;
        z-index: 3;
    }
    picture, img {
        z-index: 1;
    }
}
