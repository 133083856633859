.dropdown-menu {
    --bs-dropdown-item-padding-y: .2rem;
    --bs-dropdown-item-padding-x: 1.5rem;
    --bs-dropdown-padding-y: 1rem;
}

iframe {
    border: 0;
    vertical-align: bottom;
}
body {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    font-size: var(--body-font-size);
}
main {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}
.plyr--stopped.plyr__poster-enabled .plyr__poster {
    opacity: 1;
    background-size: cover;
}
.form-control, .form-select {

}
.page-item:not(:first-child) .page-link {
    margin-left: -1px;
}

#pre-loader {
    background-color: #fff;
    height: 100%;
    width: 100%;
    position: fixed;
    margin-top: 0;
    top: 0;
    left: 0;
    bottom: 0;
    overflow: hidden;
    right: 0;
    z-index: 999999;
}
#pre-loader img {
    position: absolute;
    text-align: center;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 99;
    margin: 0 auto;
}

#back-to-top {
    bottom: 80px;
    position: fixed;
    right: 1.25rem;
    z-index: 1032;
    background-color: var(--bs-btn-bg, #000000);
    color: #fff;
    border-radius: 0.25rem;
    width: 30px;
    height: 30px;
    display: none;
    align-items: center;
    justify-content: center;
    font-size: 1.25rem;
    cursor: pointer;
    @include transition(left 0.2s ease);
    &.show {
        display: flex;
    }
}
.text-left {
    @extend .text-start;
}
.text-right {
    @extend .text-end;
}
