.block-timeline {
    padding: 3.75rem 0;
    background-color: var(--timeline-block-bg);
    color: var(--timeline-block-text-color);
    .timeline {
        list-style: none;
        padding: 0;
        position: relative;
        &:before {
            top: 0;
            bottom: 0;
            position: absolute;
            content: " ";
            width: 2px;
            background-color: #e6e6e6;
            left: 50%;
            margin-left: -0.5px;
        }
        & >  li {
            margin-bottom: 20px;
            position: relative;
            text-align: right;
            &:before, &:after {
                content: " ";
                display: table;
            }
            &:after {
                clear: both;
            }
            & > .timeline-panel {
                width: 45%;
                float: left;
                padding: 1.5rem;
                position: relative;
                &:before {
                    position: absolute;
                    top: 30px;
                    right: -25px;
                    display: inline-block;
                    width: 15px;
                    height: 2px;
                    background: $border-color;
                    content: " ";
                }
            }
            & > .timeline-badge {
                width: 30px;
                height: 30px;
                line-height: 10px;
                border-radius: 100%;
                text-align: center;
                position: absolute;
                top: 16px;
                left: 50%;
                background: $primary;
                margin-left: -15px;
                box-shadow: $box-shadow-sm;
                div {
                    margin-top: 10px;
                    margin-left: 45px;
                    font-size: 18px;
                    font-weight: 700;
                    text-align: left;
                }
            }

            .timeline-body {
                & > p,  & > ul {
                    margin-bottom: 0;
                }
                & > p + p {
                    margin-top: 5px;
                }
            }

            &.timeline-inverted {
                text-align: left;

                & > .timeline-badge {
                    div {
                        text-align: right;
                        float: right;
                        margin-right: 45px;
                    }
                }
                & > .timeline-panel {
                    float: right;
                    &:before {
                        border-left-width: 0;
                        border-right-width: 15px;
                        left: -60px;
                        right: auto;
                    }
                    &:after {
                        border-left-width: 0;
                        border-right-width: 15px;
                        left: -60px;
                        right: auto;
                    }
                }
            }
        }

        .timeline-arrow {
            position: absolute;
            width: 100%;
            left: 0.5px;
            right: 0;
            text-align: center;
            color: #e6e6e6;
            bottom: -30px;
        }
        .timeline-title {
            font-weight: 700;
        }
        .timeline-panel {
            box-shadow: $box-shadow-lg;
            border: 1px solid rgba(164,174,198,.2);
            border-radius: $border-radius;
        }
    }
}

@include media-breakpoint-only(md) {
    .block-timeline {
        .timeline {
            & > li > .timeline-panel:before {
                display: none;
            }
        }
    }
}

@include media-breakpoint-down(md) {
    .block-timeline {
        .timeline {
            &:before {
                left: 15px;
            }
            li {
                & > .timeline-panel {
                    text-align: left;
                    width: calc(100% - 45px);
                    margin-left: 45px;
                    float: left;
                    padding-top: 3rem;
                    &:before {
                        left: -10px;
                        width: 25px;
                    }
                }
                & > .timeline-badge {
                    left: 15px;
                    div {
                        float: left;
                        text-align: left;
                        margin-left: 70px;
                        margin-right: 0;
                    }
                }
                &.timeline-inverted {
                    & > .timeline-panel {
                        float: left;
                        &:before {
                            left: -10px;
                            width: 25px;
                        }
                    }
                    & > .timeline-badge {
                        left: 15px;
                        div {
                            float: left;
                            text-align: left;
                            margin-left: 70px;
                            margin-right: 0;
                        }
                    }
                }
            }
            .timeline-arrow {
                left: 9px;
                text-align: left;
            }
        }
    }
}
